{
  "name": "enrollhere-dialer-publisher",
  "version": "0.1.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "replace-timestamp": "node ./replace.build.js",
    "lint": "ng lint",
    "explore-source-maps": "source-map-explorer dist/**/*.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-dialer-publisher ./dist/enrollhere-dialer-publisher/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-dialer-publisher ./dist/enrollhere-dialer-publisher/browser"
  },
  "private": true,
  "dependencies": {
    "@angular-eslint/builder": "^19.3.0",
    "@angular/animations": "^19.2.3",
    "@angular/cdk": "^19.2.6",
    "@angular/common": "^19.2.3",
    "@angular/compiler": "^19.2.3",
    "@angular/core": "^19.2.3",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "^19.2.3",
    "@angular/platform-browser": "^19.2.3",
    "@angular/platform-browser-dynamic": "^19.2.3",
    "@angular/router": "^19.2.3",
    "@angular/service-worker": "^19.2.3",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.2",
    "@fortawesome/pro-regular-svg-icons": "^6.7.2",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@ng-select/ng-select": "^14.2.4",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@sentry/angular": "^9.8.0",
    "@sentry/cli": "^2.42.4",
    "@swimlane/ngx-charts": "^22.0.0-alpha.0",
    "@tailwindcss/postcss": "^4.0.15",
    "@tailwindcss/typography": "^0.5.16",
    "autoprefixer": "^10.4.21",
    "libphonenumber-js": "^1.12.6",
    "lottie-web": "^5.12.2",
    "moment-timezone": "^0.5.47",
    "ngx-cookie-service": "^19.1.2",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-toastr": "^19.0.0",
    "plotly.js": "^3.0.1",
    "plotly.js-dist-min": "^3.0.1",
    "prettier": "^3.5.3",
    "replace-in-file": "^8.3.0",
    "rxjs": "~7.8.2",
    "socket.io-client": "^4.8.1",
    "ssr-window": "^5.0.0",
    "tailwindcss": "^4.0.15",
    "tslib": "^2.8.1",
    "uuid": "^11.1.0",
    "write-excel-file": "^2.3.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.4",
    "@angular-eslint/eslint-plugin": "^19.3.0",
    "@angular-eslint/eslint-plugin-template": "^19.3.0",
    "@angular/cli": "~19.2.4",
    "@angular/compiler-cli": "^19.2.3",
    "@ngrx/schematics": "^19.0.1",
    "@types/jasmine": "~5.1.7",
    "@types/plotly.js-dist-min": "^2.3.4",
    "@types/uuid": "^10.0.0",
    "daisyui": "^5.0.9",
    "eslint-config-prettier": "^10.1.1",
    "eslint-plugin-prettier": "^5.2.3",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier-eslint": "^16.3.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.8.2"
  }
}
